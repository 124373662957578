<template>
  <div
    class="how-it-works__block col-12 col-md-4 d-flex flex-row flex-wrap flex-md-column align-items-center justify-content-between text-center"
  >
    <slot />

    <div class="how-it-works__description">
      <h4 class="mt-24 mb-16">{{ title }}</h4>
      <p class="mb-16">{{ text }}</p>

      <BaseButton
        v-if="buttonLabel"
        class="d-md-none align-self-start"
        variant="secondary"
        @click="onButtonClick"
      >
        {{ buttonLabel }}
      </BaseButton>
    </div>

    <div v-if="buttonLabel" class="d-none d-md-block">
      <BaseButton variant="secondary" @click="onButtonClick">
        {{ buttonLabel }}
      </BaseButton>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true,
    },
    text: {
      type: String,
      required: true,
    },
    buttonLabel: {
      type: String,
      default: '',
    },
    onButtonClick: {
      type: Function,
      default: () => {},
    },
  },
}
</script>

<style lang="scss" scoped>
@include media-breakpoint-down(md) {
  .how-it-works__description {
    max-width: 75%;
    text-align: left;

    h4 {
      margin-top: 0;
    }
  }
}
</style>
